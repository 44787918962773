import { useEffect, useState } from 'react';

import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useCustomer } from '@hooks/customer/useCustomer';
import { REWARD_TIERS } from '@lib/constants';

export function CreateAccountLoyaltyPrompt({
  cartValue,
}: {
  cartValue: number;
}) {
  const { customer } = useCustomer();
  const [tier, setTier] = useState('welcome');

  useEffect(() => {
    setTier(customer?.loyaltyLion?.tier ?? 'welcome');
  }, [customer]);

  let pointsPerDollar = 3;
  if (REWARD_TIERS.hasOwnProperty(tier.toUpperCase())) {
    pointsPerDollar = REWARD_TIERS[tier.toUpperCase()].earn;
  }

  if (customer) {
    return (
      <Paragraph align="center">
        You're set to earn {Math.floor(pointsPerDollar * cartValue)} points
        after placing this order!
      </Paragraph>
    );
  } else {
    return (
      <Paragraph align="center">
        <TextLink href="/create-account">Create an account</TextLink> so you can
        earn {Math.floor(pointsPerDollar * cartValue)} points on this order!
      </Paragraph>
    );
  }
}
