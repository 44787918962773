import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { Button } from '@components/Button';
import { useCart } from '@hooks/cart/useCart';

export function BigCommerceCheckoutButton({
  styledButton,
  className,
}: {
  styledButton: boolean;
  className?: string;
}): ReactElement {
  const { cart } = useCart();
  const router = useRouter();

  const navigate = (
    // this type def isn't entirely true, but for the sake of keeping TS happy it's there
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();

    // No items, then we go straight to cart
    if (
      cart &&
      cart.line_items.physical_items.length === 0 &&
      cart.line_items.gift_certificates.length === 0
    ) {
      router.push('/cart');
    } else {
      // Go directly to the checkout endpoint to avoid any redirection
      // Using location.assign too because checkout isn't within Next app
      window.location.assign('/api/checkout');
    }
  };

  if (styledButton) {
    return (
      <Button href="/checkout" buttonStyle="cta" className={className}>
        Checkout
      </Button>
    );
  } else {
    return (
      // eslint-disable-next-line @next/next/no-html-link-for-pages
      <a href="/checkout" className={className}>
        Checkout
      </a>
    );
  }
}
