import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BiUser, BiUserCheck } from 'react-icons/bi';
import { useSWRConfig } from 'swr';
import { twJoin } from 'tailwind-merge';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Button } from '@components/Button';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useLogout } from '@hooks/customer/useLogout';
import { SWR_KEYS } from '@lib/constants';

import { LoginModalUncontrolled } from './LoginModal';

const ICON_SIZE = '1.75rem';

function LoggedOut() {
  return (
    <LoginModalUncontrolled
      trigger={
        <Button
          aria-label="Log in"
          className="relative w-10  rounded-full ring-grey-mid ring-offset-2 hover:scale-110
    hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
          buttonStyle={'tertiary'}
          icon={<BiUser size={ICON_SIZE} />}
        />
      }
    />
  );
}

const itemClasses =
  'select-none rounded-[3px] outline-none data-[highlighted]:bg-orange data-[highlighted]:text-white flex items-center justify-between';
const itemActionClasses = 'pl-6 pr-1 h-12 leading-[48px]';

function LoggedIn() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const logout = useLogout();
  const { mutate } = useSWRConfig();
  const router = useRouter();

  // close the quick cart on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const handleLogout = async () => {
    const succeeded = await logout();
    if (succeeded) {
      mutate(SWR_KEYS.customer);
      mutate(SWR_KEYS.alerts);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <ErrorAlert show={error} setShow={setError}>
        <Paragraph>
          There was an issue logging you out. Please try again
        </Paragraph>
      </ErrorAlert>
      <DropdownMenu.Root open={open} onOpenChange={(state) => setOpen(state)}>
        <TooltipWrapper text="Your Account">
          <DropdownMenu.Trigger asChild>
            <Button
              aria-label="Your account"
              className="relative w-10  rounded-full ring-grey-mid ring-offset-2 hover:scale-110
          hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
              buttonStyle={'tertiary'}
              icon={<BiUserCheck size="2rem" />}
            />
          </DropdownMenu.Trigger>
        </TooltipWrapper>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="z-3 min-w-[220px] rounded-md bg-white p-1 shadow-tooltip will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade dark:bg-grey-darkest dark:shadow-tooltipDark [&_a]:inline-block [&_a]:w-full"
            sideOffset={5}
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account"
                aria-label="Your account home"
                className={itemActionClasses}
              >
                Account Home
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/favourites"
                aria-label="Your favourite products"
                className={itemActionClasses}
              >
                Favourites
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/orders"
                aria-label="Previous orders"
                className={itemActionClasses}
              >
                Previous Orders
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/addresses"
                aria-label="Saved addresses"
                className={itemActionClasses}
              >
                Saved Addresses
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/settings"
                aria-label="Login & Security"
                className={itemActionClasses}
              >
                Login & Security
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/referral-program"
                aria-label="Referral program"
                className={itemActionClasses}
              >
                Referral Program
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/account/preferences"
                aria-label="Communication Preferences"
                className={itemActionClasses}
              >
                Communication Preferences
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Item className={itemClasses}>
              <Link
                href="/contact-us"
                aria-label="Customer Service & FAQs"
                className={itemActionClasses}
              >
                Customer Service & FAQs
              </Link>
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item className={itemClasses}>
              <button
                onClick={handleLogout}
                className={twJoin([itemActionClasses, 'w-full text-left'])}
              >
                Log out
              </button>
            </DropdownMenu.Item>
            <DropdownMenu.Arrow className="fill-white dark:fill-grey-darkest" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}

export function DropdownLogin() {
  const { customer } = useCustomer();

  return customer ? <LoggedIn /> : <LoggedOut />;
}
